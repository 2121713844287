import React, {
  useEffect,
  useContext,
  useRef
} from 'react'

import { useParams } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { Spinner, Popover, OverlayTrigger, Alert } from 'react-bootstrap'
import { useQueryClient, useMutation } from 'react-query'

import { ApiContext } from '../ApiContext'
import { MessagesContext } from '../MessagesContext'
import { layoutConfigFor, makeSectionUtils } from '../utils'
import usePage from '../hooks/usePage'

import ImagePicker from './ImagePicker'
import PagePicker from './PagePicker'
import UrlPicker from './UrlPicker'
import FieldSelector from './FieldSelector'
import ImageUrlField from './ImageUrlField'
import WordCounter from './WordCounter'
import DomainContext from '../DomainContext'
import AlertFormFields from './AlertFormFields'
import MarkdownInfo from './MarkdownInfo'

const SectionForm = ({ onSuccess, onCancel, id: sectionId, ...props }) => {

  const ref = useRef()
  const queryClient = useQueryClient()
  const {addMessage} = useContext(MessagesContext)

  const {domainId, pageId} = useParams()
  const {client} = useContext(ApiContext)

  const {
    domain,
    domainSettings,
    sectionDisplayModes,
  } = useContext(DomainContext)

  const {
    configuresFields,
    showsField,
    nameForMode,
    descriptionForMode
  } = makeSectionUtils(domain)

  const createOrUpdateSection = useMutation(section => {
    if (sectionId) {
      return client.updateSection(domainId, sectionId, section)
    } else {
      return client.createSection(domainId, section)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])

  const pageQuery = usePage()

  if (pageQuery.isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (pageQuery.isError) {
    return (<p className='text-danger text-danger'>{pageQuery.error.message}</p>)
  }

  const dmGroups = {}

  sectionDisplayModes.forEach(({category: cat, ...props}) => {
    dmGroups[cat] = dmGroups[cat] ? dmGroups[cat].concat([props]) : [props]
  })

  const displayModeOptions = Object.entries(dmGroups).map(([category, modes]) => (
    <optgroup label={category}>
      {modes.map(({name, description}, i) => (
        <option key={'section-display-mode-' + i} value={name}>{name}</option>
      ))}
    </optgroup>
  ))
  const displayModeInfo = (
    <Popover id='display-mode-info'>
      <Popover.Header className='fw-bold'>Display Mode Info</Popover.Header>
      <Popover.Body>
        <p>
          Decide how this Section is shown on the website.
          Most of the time tells you how its items will be laid out.
        </p>
        {Object.entries(dmGroups).map(([category, modes]) => (
          <React.Fragment key={category}>
            <h6 className='fw-bold'>{category}</h6>
            <dl className='mb-0'>
              {modes.map(({name, description}) => (
                <React.Fragment key={name}>
                  <dt>{name}</dt>
                  <dd>{description}</dd>
                </React.Fragment>
              ))}
            </dl>
          </React.Fragment>
        ))}
      </Popover.Body>
    </Popover>
  )

  const {
    headline,
    lead_text,
    body,
    published,
    display_mode,
    page_id,
    image_id,
    image_url,
    target_page_path,
    target_link_text,
    target_url,
  } = props

  const {section_image_config} = layoutConfigFor(pageQuery.data.layout, domainSettings)

  return (
    <div ref={ref} className='p-3 shadow'>
      <h3>{sectionId ? 'Edit Section' : 'New Section'}</h3>
      <Formik
        initialValues={{
          headline,
          lead_text,
          body,
          published,
          display_mode,
          page_id,
          image_id,
          image_url,
          target_page_path,
          target_link_text,
          target_url,
        }}
        onSubmit={(values, formikBag) => {
          createOrUpdateSection.mutate(values, {
            onSuccess: () => {
              formikBag.setSubmitting(false)
              onSuccess()
            },
            onError: (err) => {
              addMessage(`Cannot save Section: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            }
          })
        }}
      >
        {({isSubmitting, values: {image_url, display_mode, body}}) => (
          <Form className='vstack gap-3'>
            <div className='form-group'>
              <label className='form-label'>
                Section Display Mode
                <OverlayTrigger
                  trigger='click'
                  placement='right'
                  overlay={displayModeInfo}
                  rootClose={true}
                >
                  <span className='badge bg-dark rounded-pill ms-1' >?</span>
                </OverlayTrigger>
              </label>
              <Field
                as='select'
                name='display_mode'
                className='form-select'
              >
                <option value="">--Select Display Mode--</option>
                { displayModeOptions }
              </Field>
              <div className='form-text'>
                Decide how this Section is shown on the website.
                Most of the time tells you how its items will be laid out.
              </div>
            </div>
            {!configuresFields(display_mode) && (<AlertFormFields/>)}
            <Alert variant='info' className='m-0'>
              <strong>{nameForMode(display_mode)}:</strong>
              {' '}{ descriptionForMode(display_mode) }
            </Alert>
            {showsField(display_mode, 'headline') && (
              <div className='form-group mb-3'>
              <label className='form-label'>Headline</label>
              <Field
                type='text'
                name='headline'
                autoFocus
                className='form-control'
              />
            </div>)}
            {showsField(display_mode, 'lead_text') && (
              <div className='form-group mb-3'>
              <label className='form-label'>Lead Text</label>
              <Field
                type='text'
                as='textarea'
                rows='3'
                name='lead_text'
                className='form-control'
              />
              <div className='form-text'>
                No formatting (no links, no bold, no lists)
              </div>
            </div>)}
            {showsField(display_mode, 'image') && (
              <div className='form-group mb-3'>
              <label className='form-label'>Image</label>
              {image_url && (
                <div className='mb-2'>
                  <img src={image_url} className='img-fluid mb-1' alt='' />
                </div>
              )}
              <ImagePicker
                name='image_id'
              />
              <ImageUrlField
                name='image_url'
                imageWidth={section_image_config.width}
                imageHeight={section_image_config.height}
              />
            </div>)}
            {showsField(display_mode, 'body') && (
              <div className='form-group mb-3'>
              <label className='form-label'>Body</label>

              <Field
                type='text'
                as='textarea'
                name='body'
                rows={10}
                className='form-control mb-1'
              />
              <WordCounter text={body} />
              <MarkdownInfo />
            </div>)}
            {showsField(display_mode, 'link') && (
              <div className='form-group mb-3'>
              <label className='form-label'>Link</label>
              <FieldSelector
                secondaryField={<UrlPicker name='target_url' />}
              >
                <PagePicker
                  name='target_page_path'
                  linkTextField='target_link_text'
                  pages={domain.pages}
                />
              </FieldSelector>
            </div>)}
            {showsField(display_mode, 'link_text') && (
              <div className='form-group mb-3'>
              <label className='form-label'>Link Text</label>
              <Field
                type='text'
                name='target_link_text'
                className='form-control'
              />
            </div>)}

            <div className='form-group'>
              <div className="form-check">
                <Field
                  type='checkbox'
                  id='published'
                  name='published'
                  className='form-check-input'
                />
                <label
                  className="form-check-label"
                  htmlFor="published"
                >
                  Published
                </label>
              </div>
            </div>
            <div className='form-group'>
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-sm btn-outline-primary me-2'
              >
                Save
              </button>
              <button
                type='button'
                disabled={isSubmitting}
                onClick={onCancel}
                className='btn btn-sm btn-outline-secondary'
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SectionForm
