import React from 'react'

const MarkdownInfo = () => (
  <span className='form-text'>
    You can use{' '}
    <a
      href='https://commonmark.org/help/'
      rel='noreferrer'
      target='_blank'
    >
      Markdown
    </a>{' '}
    here with some{' '}
    <a
      href='https://www.markdownguide.org/extended-syntax/#tables'
      rel='noreferrer'
      target='_blank'
    >
      extended Syntax
    </a>
    . Internal links always start with a slash, e.g.{' '}
    <code>[Link Text](/my-page)</code>.
    On{' '}
    <a
      href='https://github.com/austauschkompass/austauschkompass/wiki/Gatsby-CMS-Sites'
      rel='noreferrer'
      target='_blank'
    >Gatsby sites</a>{' '}
    they also end with a slash, e.g.{' '}
    <code>[Link Text](/my-page/)</code>. You can set
    nofollow-Links with{' '}
    <code className='d-inline-block'>
      {`<a href="https://example.com/page/xyz" rel="nofollow">Link Text</a>`}
    </code>
    . If something doesn't work via Markdown, chances are there is
    another way to achieve the same thing with our CMS.
  </span>
)

export default MarkdownInfo
