import React, {
  useEffect,
  useContext,
  useRef,
} from 'react'

import {useParams} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import {useQueryClient, useMutation} from 'react-query'
import {Spinner, Popover, OverlayTrigger, Alert} from 'react-bootstrap'

import {ApiContext} from '../ApiContext'
import {MessagesContext} from '../MessagesContext'
import {layoutConfigFor, imageConfigFor, makeItemUtils} from '../utils'
import usePage from '../hooks/usePage'

import ImagePicker from './ImagePicker'
import PagePicker from './PagePicker'
import UrlPicker from './UrlPicker'
import FieldSelector from './FieldSelector'
import ComponentConfigurator from './ComponentConfigurator'
import ImageUrlField from './ImageUrlField'
import WordCounter from './WordCounter'
import DomainContext from '../DomainContext'
import AlertFormFields from './AlertFormFields'
import MarkdownInfo from './MarkdownInfo'

const ItemForm = ({ onSuccess, onCancel, id: itemId, ...props }) => {
  const ref = useRef()

  const queryClient = useQueryClient()
  const {addMessage} = useContext(MessagesContext)

  const {domainId, pageId} = useParams()
  const pageQuery = usePage()
  const {client} = useContext(ApiContext)
  const {
    domain,
    domainSettings,
    itemDisplayModes
  } = useContext(DomainContext)
  const {configuresFields, showsField, nameForMode, descriptionForMode} = makeItemUtils(domain)

  const createOrUpdateItem = useMutation(item => {
    if (itemId) {
      return client.updateItem(domainId, itemId, item)
    } else {
      return client.createItem(domainId, item)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  const {
    headline,
    body,
    intro,
    display_mode,
    published,
    section_id,
    image_url,
    image_id,
    target_page_path,
    target_link_text,
    target_url,
    has_component,
    component_name,
    component_config,
  } = props

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [ref])

  if (pageQuery.isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (pageQuery.isError) {
    return (<p className='text-danger text-danger'>{pageQuery.error.message}</p>)
  }

  const displayModeOptions = itemDisplayModes.map(
    ({ name }, i) => {
      return (
        <option key={'items-display-mode-' + i} value={name}>
          {name}
        </option>
      )
    },
  )

  const displayModeInfo = (
    <Popover id='display-mode-info'>
      <Popover.Header as='h3'>Display Mode Info</Popover.Header>
      <Popover.Body>
        <p>
          Decide how this Item is shown on the website.
        </p>
        <dl className='mb-0'>
          {itemDisplayModes.map(({name, description}) => (
            <React.Fragment key={name}>
              <dt>{name}</dt>
              <dd>{description}</dd>
            </React.Fragment>
          ))}
        </dl>
      </Popover.Body>
    </Popover>
  )

  const {item_image_config} = layoutConfigFor(pageQuery.data.layout, domainSettings)

  // NOTE: we use this function to be able to pass in the current form value
  // for display mode to (re)generate this whenever we choose a different one.
  // Otherwise the fetched value from api goes stale...
  const imageConfigForMode = (displayMode) => {
    return imageConfigFor(displayMode, itemDisplayModes, item_image_config)
  }

  return (
    <div ref={ref} className='p-3 shadow'>
      <h4>{itemId ? 'Edit Item' : 'New Item'}</h4>

      <Formik
        initialValues={{
          headline,
          body,
          intro,
          published,
          section_id,
          image_url,
          image_id,
          target_page_path,
          target_link_text,
          target_url,
          display_mode,
          has_component,
          component_name,
          component_config,
        }}
        onSubmit={(values, formikBag) => {
          createOrUpdateItem.mutate(values, {
            onSuccess: () => {
              formikBag.setSubmitting(false)
              onSuccess()
            },
            onError: (err) => {
              addMessage(`Cannot save Item: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            },
          })
        }}
      >
        {({
          isSubmitting,
          values: { image_url, body, display_mode },
        }) => (
          <Form className='vstack gap-3'>
            <div className='form-group'>
              <label className='form-label'>
                Display Mode
                <OverlayTrigger
                  trigger='click'
                  placement='right'
                  overlay={displayModeInfo}
                  rootClose={true}
                >
                  <span className='badge bg-dark rounded-pill ms-1'>?</span>
                </OverlayTrigger>
              </label>

              <Field as='select' name='display_mode' className='form-select'>
                <option value=''>--Select Display Mode--</option>
                {displayModeOptions}
              </Field>
              <div className='form-text'>
                Decide how this Item is shown on the website.
              </div>
            </div>

            {!configuresFields(display_mode) && (<AlertFormFields/>)}

            <Alert variant='info' className='m-0'>
              <p className='m-0'>
                <strong className=''>{nameForMode(display_mode)}:</strong>{' '}
                  {descriptionForMode(display_mode)}
              </p>
            </Alert>

            {showsField(display_mode, 'headline') && (
              <div className='form-group'>
                <label className='form-label'>Headline</label>
                <Field
                  type='text'
                  name='headline'
                  autoFocus
                  className='form-control'
                />
              </div>
            )}
            {showsField(display_mode, 'image') && (
              <div className='form-group'>
                <label className='form-label'>Image</label>
                {image_url && (
                  <div className='mb-2'>
                    <img src={image_url} className='mb-1' alt='' />
                  </div>
                )}
                <ImagePicker name='image_id' />
                <ImageUrlField
                  name='image_url'
                  imageWidth={imageConfigForMode(display_mode).width}
                  imageHeight={imageConfigForMode(display_mode).height}
                />
              </div>
            )}
            {showsField(display_mode, 'intro') && (
              <div className='form-group'>
                <label className='form-label'>Intro/Lead</label>
                <Field
                  type='text'
                  as='textarea'
                  name='intro'
                  rows={3}
                  className='form-control'
                />
                <WordCounter text={intro} />
              </div>
            )}
            {showsField(display_mode, 'body') && (
              <div className='form-group'>
                <label className='form-label'>Body</label>
                <Field
                  type='text'
                  as='textarea'
                  name='body'
                  rows={5}
                  className='form-control'
                />
                <WordCounter text={body} />
                <MarkdownInfo />
              </div>
            )}

            {showsField(display_mode, 'link') && (
              <div className='form-group'>
                <label className='form-label'>Link</label>
                <FieldSelector secondaryField={<UrlPicker name='target_url' />}>
                  <PagePicker
                    name='target_page_path'
                    linkTextField='target_link_text'
                    pages={domain.pages}
                  />
                </FieldSelector>
              </div>
            )}
            {showsField(display_mode, 'link_text') && (
              <div className='form-group'>
                <label className='form-label'>Link Text</label>
                <Field
                  type='text'
                  name='target_link_text'
                  className='form-control'
                />
              </div>
            )}
            {showsField(display_mode, 'component') && (
              <div className='form-group'>
                <label className='form-label'>Component</label>
                <ComponentConfigurator
                  name='component_config'
                  nameField='component_name'
                  enabledField='has_component'
                />
              </div>
            )}

            <div className='form-group'>
              <div className='form-check'>
                <Field
                  type='checkbox'
                  id='published'
                  name='published'
                  className='form-check-input'
                />
                <label className='form-check-label' htmlFor='published'>
                  Published
                </label>
              </div>
            </div>

            <div className='form-group'>
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-sm btn-outline-primary me-2'
              >
                Save
              </button>
              <button
                type='button'
                disabled={isSubmitting}
                onClick={onCancel}
                className='btn btn-sm btn-outline-secondary'
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ItemForm
